"use client";
import React, { createContext, useContext, useState, useEffect } from "react";
import { createSupabaseBrowserClient } from "@/lib/supabase/browser-client";

interface ExtracurricularRecommendation {
  id: string;
  created_at: string;
  user_id: string;
  overview: string;
  impact: string;
  action_plan: string;
}

export const ExtracurricularRecommendationContext = createContext<{
  extracurricularRecommendationData: ExtracurricularRecommendation[];
  refreshExtracurricularRecommendationData: () => void;
}>({
  extracurricularRecommendationData: [],
  refreshExtracurricularRecommendationData: () => {},
});

export const ExtracurricularRecommendationProvider = ({ children }: any) => {
  const [
    extracurricularRecommendationData,
    setExtracurricularRecommendationData,
  ] = useState<ExtracurricularRecommendation[]>([]);

  const supabase = createSupabaseBrowserClient();

  const fetchExtracurricularRecommendationData = async () => {
    const session = await supabase.auth.getSession();

    const { data, error } = await supabase
      .from("extracurricular_recommendations")
      .select("*")
      .eq("user_id", session.data.session?.user.id)
      .eq("hidden", false);

    if (data) {
      setExtracurricularRecommendationData(data);
    }
    console.log(data, error);
  };

  useEffect(() => {
    fetchExtracurricularRecommendationData();
  }, []);

  const refreshExtracurricularRecommendationData = () => {
    fetchExtracurricularRecommendationData();
  };

  return (
    <ExtracurricularRecommendationContext.Provider
      value={{
        extracurricularRecommendationData,
        refreshExtracurricularRecommendationData,
      }}
    >
      {children}
    </ExtracurricularRecommendationContext.Provider>
  );
};
