"use client";
import React, { createContext, useContext, useState, useEffect } from "react";
import { createSupabaseBrowserClient } from "@/lib/supabase/browser-client";

export const UserContext = createContext({
  userData: null,
  refreshUserData: () => {},
});

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const supabase = createSupabaseBrowserClient();

  const fetchUserData = async () => {
    const session = await supabase.auth.getSession();
    const { data, error } = await supabase
      .from("users")
      .select("*")
      .eq("uid", session.data.session?.user.id);

    if (data) {
      setUserData(data[0]);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const refreshUserData = () => {
    fetchUserData();
  };

  return (
    <UserContext.Provider value={{ userData, refreshUserData }}>
      {children}
    </UserContext.Provider>
  );
};
