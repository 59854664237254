"use client";
import React, { createContext, useContext, useState, useEffect } from "react";
import { createSupabaseBrowserClient } from "@/lib/supabase/browser-client";

interface Idea {
  id: string;
  title: string;
  prompt: string;
  type: string;
  status: string;
  user_id: string;
  hidden: boolean;
  thread_id: string;
  grade: string;
  grade_description: string;
  vessel: string;
  lesson: string;
  additional_suggestions: string;
}

export const IdeaContext = createContext<{
  ideaData: Idea[];
  refreshIdeaData: () => void;
}>({
  ideaData: [],
  refreshIdeaData: () => {},
});

export const IdeaProvider = ({ children }) => {
  const [ideaData, setIdeaData] = useState([]);
  const supabase = createSupabaseBrowserClient();

  const fetchIdeaData = async () => {
    const session = await supabase.auth.getSession();

    const { data, error } = await supabase
      .from("ideas")
      .select("*")
      .eq("user_id", session.data.session?.user.id)
      .eq("hidden", false);

    if (data) {
      setIdeaData(data);
    }
  };

  useEffect(() => {
    fetchIdeaData();
  }, []);

  const refreshIdeaData = () => {
    fetchIdeaData();
  };

  return (
    <IdeaContext.Provider value={{ ideaData, refreshIdeaData }}>
      {children}
    </IdeaContext.Provider>
  );
};
