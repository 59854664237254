'use client';
import { createSupabaseBrowserClient } from "@/lib/supabase/browser-client";
import { createContext, useEffect, useState } from "react";

const lengthFilterMap = {
   long: {max: 20000, min: 250},
   medium: {max: 250, min: 51},
   short: {max: 51, min: 0},
   all: {max: 20000, min: -1},
};

export const EssaysContext = createContext<{
    essays: any[];
    shortEssays: any[];
    mediumEssays: any[];
    longEssays: any[];
    commonAppEssays: any[];
    updateEssays: () => {}
}>({
    essays: [],
    shortEssays: [],
    mediumEssays: [],
    longEssays: [],
    commonAppEssays: [],
    updateEssays: () => {} 
});

export const EssaysProvider = ({children}) => {
    const [essays, setEssay] = useState<any[]>([]);
    const [shortEssays, setShortEssays] = useState<any[]>([]);
    const [mediumEssays, setMediumEssays] = useState<any[]>([]);
    const [longEssays, setLongEssays] = useState<any[]>([]);
    const [commonAppEssays, setCommonAppEssays] = useState<any[]>([]);

    const supabase = createSupabaseBrowserClient();

    useEffect(() => {
        async function fixContent() {
            const {data} = await supabase.from("essays").select();
            setEssay(data);
            let g = [...data];
            var d = g.filter((essay) => essay.word_limit < lengthFilterMap.medium.max && essay.word_limit > lengthFilterMap.medium.min && !essay.commonapp);
            setMediumEssays(d);
            var d1 = data.filter((essay) => essay.word_limit <= lengthFilterMap.short.max && !essay.commonapp);
            setShortEssays(d1);
            var d2 = data.filter((essay) => essay.word_limit >= lengthFilterMap.long.min && !essay.commonapp);
            setLongEssays(d2);
            var d3 = data.filter((essay) => essay.commonapp);
            setCommonAppEssays(d3);
        }
        fixContent();
    }, []);

    async function updateEssays() {
        const {data} = await supabase.from("essays").select();
        setEssay(data);
        let g = [...data];
        var d = g.filter((essay) => essay.word_limit < lengthFilterMap.medium.max && essay.word_limit > lengthFilterMap.medium.min && !essay.commonapp);
        setMediumEssays(d);
        var d1 = data.filter((essay) => essay.word_limit <= lengthFilterMap.short.max && !essay.commonapp);
        setShortEssays(d1);
        var d2 = data.filter((essay) => essay.word_limit >= lengthFilterMap.long.min && !essay.commonapp);
        setLongEssays(d2);
        var d3 = data.filter((essay) => essay.commonapp);
        setCommonAppEssays(d3);
    }

    return (
        <EssaysContext.Provider value={{essays, shortEssays, mediumEssays, longEssays, commonAppEssays, updateEssays}}>
            {children}
        </EssaysContext.Provider>
    )

};
