"use client";
import React, { createContext, useContext, useState, useEffect } from "react";
import { createSupabaseBrowserClient } from "@/lib/supabase/browser-client";

interface Background {
  title: string;
  user_id: string;
  resume_url: string;
  awards: string;
  challenges: string;
  identity: string;
  core_values: string;
  personality: string;
}

export const BackgroundContext = createContext<{
  backgroundData: Background;
  refreshBackgroundData: () => void;
}>({
  backgroundData: {
    title: "",
    user_id: "",
    resume_url: "",
    awards: "",
    challenges: "",
    identity: "",
    core_values: "",
    personality: "",
  },
  refreshBackgroundData: () => {},
});

export const BackgroundProvider = ({ children }) => {
  const [backgroundData, setBackgroundData] = useState<Background>({
    title: "",
    user_id: "",
    resume_url: "",
    awards: "",
    challenges: "",
    identity: "",
    core_values: "",
    personality: "",
  });
  const supabase = createSupabaseBrowserClient();

  const fetchBackgroundData = async () => {
    const session = await supabase.auth.getSession();

    const { data, error } = await supabase
      .from("background")
      .select("*")
      .eq("user_id", session.data.session?.user.id);

    if (data) {
      setBackgroundData(data[0]);
    }
  };

  useEffect(() => {
    fetchBackgroundData();
  }, []);

  const refreshBackgroundData = () => {
    fetchBackgroundData();
  };

  return (
    <BackgroundContext.Provider
      value={{ backgroundData, refreshBackgroundData }}
    >
      {children}
    </BackgroundContext.Provider>
  );
};
